import {accountService} from './services/Account.service';
import sweetAlert from "sweetalert2";
import {citiesService} from "./services/Cities.service";
import {productsService} from "./services/Products.service";
import {paymentsService} from "./services/Payments.service";
//---- CONFIGURACION GRAL DE JS ----//
let quantityProd = 1;
document.addEventListener('DOMContentLoaded', function () {

    const passStatus = document.getElementById('pass-status');
    const passwordField = document.getElementById('password-field');
    const requirementsList = document.getElementById('password-requirements');

    if (passStatus && passwordField && requirementsList) {

        passwordField.value = generatePassword();

        passwordField.type = 'text'; // Hacer la contraseña visible

        passStatus.addEventListener('click', function () {
            if (passwordField.type === 'password') {
                passwordField.type = 'text';
                passStatus.classList.remove('fa-eye');
                passStatus.classList.add('fa-eye-slash');
            } else {
                passwordField.type = 'password';
                passStatus.classList.remove('fa-eye-slash');
                passStatus.classList.add('fa-eye');
            }
        });

        passwordField.addEventListener('input', function () {
            var password = passwordField.value;

            // Verificar si la contraseña tiene al menos 8 caracteres
            if (password.length >= 8) {
                document.getElementById('length').classList.add('met');
            } else {
                document.getElementById('length').classList.remove('met');
            }

            // Verificar si la contraseña tiene al menos una letra mayúscula
            if (/[A-Z]/.test(password)) {
                document.getElementById('uppercase').classList.add('met');
            } else {
                document.getElementById('uppercase').classList.remove('met');
            }

            // Verificar si la contraseña tiene al menos un número
            if (/\d/.test(password)) {
                document.getElementById('number').classList.add('met');
            } else {
                document.getElementById('number').classList.remove('met');
            }

            // Verificar si la contraseña tiene al menos un caracter especial
            if (/[!@#$%^&*()_\-+=;:,.?]/.test(password)) {
                document.getElementById('special').classList.add('met');
            } else {
                document.getElementById('special').classList.remove('met');
            }
        });

        passwordField.addEventListener('focus', function () {
            requirementsList.style.display = 'block';
        });

        passwordField.addEventListener('blur', function () {
            requirementsList.style.display = 'none';
        });

    }

    // Generar una contraseña y establecerla como valor por defecto del campo de contraseña
    $('#create_account').change(function () {
        if ($(this).is(':checked')) {
            $('.container_field_password').show();
        } else {
            $('.container_field_password').hide();
        }
    });

});

let statusAddress = false;
let statusRfc = false;
let is_guest = false;

function generatePassword() {
    let length = 8, charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-=+;:,.?",
        retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function valFormulariosV3(form = null) {
    let _action = true;
    let _nameField = '';
    let inputs = '';

    if (form !== null) {
        const _form = document.querySelector('.' + form);
        inputs = _form.getElementsByClassName('required');
    } else {
        inputs = document.getElementsByClassName('required');
    }

    //let _this = this;

    let convertToArray = Array.from(inputs);

    for (let i = 0; i < convertToArray.length; i++) {
        let item = convertToArray[i];
        if (item.classList.contains('required')) {
            if (item.getAttribute('type')) {
                //console.log('type = ' + item.getAttribute('type'));
                switch (item.getAttribute('type')) {
                    case 'hidden':
                    case 'text':
                    case 'tel':
                    case 'password':
                    case 'date':
                        if (item.value === undefined || item.value === '' || item.value === '0' || item.value.length === 0) {
                            _nameField = item.getAttribute('placeholder');
                            _action = false;
                        }
                        break;
                    case 'number':
                        //console.log('number = ' + item.value);
                        if (item.value === undefined || item.value === '') {
                            _nameField = item.getAttribute('placeholder');
                            _action = false;
                        }
                        break;
                    case 'email':
                        if (item.value === undefined || item.value === '' || item.value === '0' || item.value.length === 0) {
                            if (validarEmail(item.value) === false) {
                                _nameField = item.getAttribute('placeholder');
                                _action = false;
                            }
                        }
                        break;
                    case 'radio':
                    case 'checkbox':
                        if (item.checked === false) {
                            _nameField = item.getAttribute('placeholder');
                            _action = false;
                        }
                        break;
                    case 'file':
                        //console.log(item.files.length);
                        if (item.files.length === 0) {
                            //_nameField = item.getAttribute('data-input-name');
                            _nameField = item.dataset.nameInput;
                            _action = false;
                        }
                        break;
                }
            } else {
                if (item.dataset.select === 'on' || item.dataset.textarea === 'on') {
                    //console.log('Select/Textarea valor de item.value = ' + item.value);
                    if (item.value === undefined || item.value === '' || item.value === '0' || item.value === "-1") {
                        _nameField = item.dataset.nameInput;
                        //console.log('Select/Textarea _nameField = ' + _nameField);
                        _action = false;
                        break;
                    }
                }
            }

        }//END IF CLASS REQUIRED

        //console.log(item, _action);
        if (_action === false) {
            //console.log('haciendo el break');
            break;
        }
    }//END FOR

    if (_action === true) {
        return true;
    } else {
        //console.log('Recogiendo el valor _nameField = ' + _nameField);
        return showAlert('error', '¡Ops!', 'Este campo es requerido: ' + _nameField);
    }

}

function validarEmail(email) {
    let expr = /^([a-zA-Z0-9_.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!expr.test(email))
        // alert("Error: La dirección de correo " + email + " es incorrecta.");
        return false;
}

function showAlert(kindAlert, title, msg, reload = false) {
    sweetAlert.fire({
        title: title, text: msg, icon: kindAlert
    }).then(function () {
        if (reload === true) {
            location.reload();
        }
    })
}

function getAlert(getResponse, _reload = false) {
    let _msg = '';
    let _kindAlert = '';
    let _title = '';
    switch (getResponse) {
        case 'added_prod':
            _msg = 'El producto ha sido agregado al carrito.';
            _kindAlert = 'success';
            _title = '¡Enhorabuena!';
            break;
        case 'deleted':
            _msg = 'El registro se elimino correctamente.';
            _kindAlert = 'success';
            _title = '¡Eliminado!';
            break;
        case 'deleted_prod':
            _msg = 'El producto se elimino correctamente.';
            _kindAlert = 'success';
            _title = '¡Eliminado!';
            break;
        case 'registered':
            _msg = 'El registro ya existe, intenta con uno diferente.';
            _kindAlert = 'warning';
            _title = '¡Ops!';
            break;
        case 'fail':
            _msg = 'Algo Salio mal, intenta de nuevo.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;
        case 'no_terms':
            _msg = 'Por favor debes seleccionar los términos y condiciones.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;
        case 'get_payment_method':
            _msg = 'Por favor debes seleccionar el metodo de pago.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;
        case 'error_picture':
            _msg = 'Algo Salio mal, la imagen puede que el formato se incorrecto o la imagen es muy pesada.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;
        case 'no-picture':
            _msg = 'Algo Salio mal, la imagen del afiliado no existe vuelva a subirla.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;
        case 'updated':
            _msg = 'El registro se actualizó correctamente';
            _kindAlert = 'success';
            _title = '¡Enhorabuena!';
            break;
        case 'success':
            _msg = 'El registro se guardo correctamente.';
            _kindAlert = 'success';
            _title = '¡Enhorabuena!';
            break;
        case 'order_saved':
            _msg = 'La orden ha sido guardada correctamente.';
            _kindAlert = 'success';
            _title = '¡Enhorabuena!';
            break;
        case 'added':
            _msg = 'La diercción se guardo correctamente.';
            _kindAlert = 'success';
            _title = '¡Enhorabuena!';
            break;
        case 'payment_limit_reached':
            _msg = 'Se ha alcanzado el límite máximo de compra. Por favor, intenta por transferencia bancaria.';
            _kindAlert = 'error';
            _title = '¡Ops!';
            break;


    }
    showAlert(_kindAlert, _title, _msg, _reload);

}

export function addToCart(id, price, sku, name, category, type_product, img_product, friendly_url) {

    let cart = getCartFromCookie();
    let _subTotal = (price / 1.16);
    let _iva = _subTotal * 0.16;
    if (cart === null) {
        cart = {
            products: {}, client_guest: {}, client_address: {}, totalCart: {}
        };
    }

    if (cart.products && cart.products[id] === undefined) {
        cart.products[id] = {
            id: id,
            quantity: Number(quantityProd),
            unit_price: price,
            subtotal: _subTotal,
            iva: _iva,
            total: _subTotal + _iva,
            sku: sku,
            name: name,
            category: category,
            type_product: type_product,
            img_product: img_product,
            friendly_url: friendly_url
        };
    } else {
        if (cart.products && quantityProd !== cart.products[id].quantity) {
            cart.products[id].quantity = quantityProd
        }
    }

    document.cookie = 'cart=' + encodeURIComponent(JSON.stringify(cart)) + "; SameSite=None; Secure; path=/";

    getAlert('added_prod', true);
}

function getCartFromCookie() {
    let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)cart\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let cart;
    try {
        cart = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : null;
    } catch (e) {
        console.error('Error parsing cart cookie:', e);
        cart = null;
    }
    return cart;
}

function removeFromCart(productId) {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            // Obtener el carrito de la cookie
            let cart = getCartFromCookie();

            // Verificar si el producto existe en el carrito
            if (cart['products'][productId]) {
                // Eliminar el producto del carrito
                delete cart['products'][productId];

                // Guardar el carrito modificado de nuevo en la cookie
                document.cookie = 'cart=' + encodeURIComponent(JSON.stringify(cart)) + "; SameSite=None; Secure; path=/";

                getAlert('deleted_prod', true);

            } else {
                console.error('El producto con id ' + productId + ' no se encuentra en el carrito.');
            }
        }
    })


}

function updateTotal() {
    // Selecciona todos los elementos price_quantity
    let priceQuantityElements = document.querySelectorAll('.price_quantity');

    // Inicializa el total de los productos a 0
    let totalProd = 0;

    // Suma el valor de cada producto al total de los productos
    priceQuantityElements.forEach(function (element) {
        // Remueve el signo de $ y la coma, y convierte a número
        let productTotal = parseFloat(element.textContent.replace('$', '').replace(',', ''));
        totalProd += productTotal;
    });

    // Calcula el subtotal dividiendo el total de los productos entre 1.16
    let subTotalNumber = totalProd / 1.16;

    // Actualiza el subtotal en la página
    let subTotal = document.querySelector('.subTotalCart');
    subTotal.textContent = '$' + subTotalNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    let shipping = document.querySelector('.shippingCart');

    // Remueve el signo de $ y la coma, y convierte a número
    let shippingNumber = parseFloat(shipping.textContent.replace('$', '').replace(',', ''));

    // Calcula el IVA sumando el subtotal y el envío, y multiplicando el resultado por 0.16
    let ivaElement = document.querySelector('.ivaCart');
    let ivaNumber = (subTotalNumber + shippingNumber) * 0.16;
    ivaElement.textContent = '$' + ivaNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Calcula el total sumando el subtotal y el IVA
    let totalNumber = subTotalNumber + ivaNumber;

    // Formatea el total a 2 decimales y agrega el signo de $
    let total = document.querySelector('.totalCart');
    total.textContent = '$' + totalNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");


}

function updateProductQuantityInCart(productId, quantity) {
    // Obtener el carrito de la cookie
    let cart = getCartFromCookie();

    // Verificar si el producto existe en el carrito
    if (cart.products && cart.products[productId]) {
        // Actualizar la cantidad del producto
        cart.products[productId].quantity = Number(quantity);

        // Guardar el carrito modificado de nuevo en la cookie
        document.cookie = 'cart=' + encodeURIComponent(JSON.stringify(cart)) + "; SameSite=None; Secure; path=/";
    } else {
        console.error('El producto con id ' + productId + ' no se encuentra en el carrito.');
    }
}

function fillAddressInputs() {
    // Obtener el id de la dirección seleccionada
    let selectedAddressId = document.getElementById('addresses').value;

    // Obtener las direcciones del atributo data-addresses
    let addresses = JSON.parse(document.getElementById('address-form').dataset.addresses);

    // Buscar la dirección en addresses
    let selectedAddress = addresses.find(function (address) {
        return address.id == selectedAddressId;
    });

    //console.log('selectedAddress - ', selectedAddress);

    // Llenar los inputs con la información de la dirección seleccionada
    document.getElementById('address').value = selectedAddress.address;
    document.getElementById('exterior_number').value = selectedAddress.exterior_number;
    document.getElementById('interior_number').value = selectedAddress.interior_number;
    document.getElementById('neighborhood').value = selectedAddress.neighborhood;
    document.getElementById('zip_code').value = selectedAddress.zip_code;
    document.getElementById('contact_name').value = selectedAddress.contact_name;
    document.getElementById('contact_phone').value = selectedAddress.contact_phone;
    document.getElementById('state').value = selectedAddress.rel_state.estado;
    document.getElementById('ciudades').value = selectedAddress.rel_city.ciudad;

    // Repite este paso para los demás campos (colonia, código postal, etc.)
}

function generateInvoiceCode(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function deleteCartCookie() {
    document.cookie = "cart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function confirmPayment() {
    // Verificar si el checkbox está seleccionado
    if (!$('.input-check__input').is(':checked')) {
        getAlert('no_terms');
        return;
    }

    if ($('.input-radio__input').filter(':checked').length === 0) {
        getAlert('get_payment_method');
        return;
    }

    if (is_guest) {
        let result = valFormulariosV3('guest_form');
        if (result === true) {
            saveOrder();
        }
    } else {
        saveOrder();
    }
}

async function saveOrder() {
    let orderProcess = '';
    // Obtener el carrito de la cookie
    let cart = getCartFromCookie();
    let kind_guest = 0;
    let resp = '';
    // Generar un código de factura de 6 dígitos alfanuméricos
    let invoiceCode = generateInvoiceCode(6);

    if ($('.is_authenticated').length) {
        //console.log('esta logeado');
        //SI ESTA LOGEADO VAMOS A SACAR EL ID DEL LA DIRECCION SELECIONADA
        cart.totalCart.client_address_id = Number($('#addresses').val());
        cart.totalCart.client_id = Number($('.client_id').val());

        cart.client_guest = {
            names: $('#checkout-first-name').val(),
            lastnames: $('#checkout-last-name').val(),
            email: $('#client_email').val(),
            whatsapp: $('#profile-phone').val(),
        }
    } else {
        //SI EL USUARIO ES INVITADO HAY QUE GUARDA LA DIRECCION QUE SE INGRESO Y DATOS DE CONTACTO
        //console.log('valor del is_guest=',$('#create_account').val())
        if ($('#create_account').is(':checked')) {
            kind_guest = $('#create_account').val();
        } else {
            kind_guest = 2;
        }

        //console.log('valor del is_guest=',kind_guest)

        cart.client_guest = {
            names: $('#checkout-first-name').val(),
            lastnames: $('#checkout-last-name').val(),
            email: $('#profile-email').val(),
            whatsapp: $('#profile-whatsapp').val(),
            is_guest: Number(kind_guest)
        }

        cart.client_address = {
            address: $('#address').val(),
            neighborhood: $('#neighborhood').val(),
            exterior_number: $('#exterior_number').val(),
            interior_number: $('#interior_number').val(),
            zip_code: Number($('#zip_code').val()),
            state: Number($('#state').val()),
            city: Number($('#ciudades').val()),
            contact_name: $('#contact_name').val(),
            contact_phone: $('#contact_phone').val()
        };

    }
    // Agregar la información adicional al carrito
    cart.totalCart.subTotal = $('.subTotalCart').text();
    cart.totalCart.shipping = $('.shippingCart').text();
    cart.totalCart.iva = $('.ivaCart').text();
    cart.totalCart.total = $('.total_cart').text();
    cart.totalCart.invoiceCode = invoiceCode;
    cart.totalCart.clientNote = $('#client_note').val();
    cart.totalCart.paymentMethod = $('input[name="checkout_payment_method"]:checked').val();

    // Guardar el carrito modificado de nuevo en la cookie
    document.cookie = 'cart=' + encodeURIComponent(JSON.stringify(cart)) + "; SameSite=None; Secure; path=/";

    // Obtener el valor del checkbox
    let paymentMethod = document.querySelector('input[name="checkout_payment_method"]:checked').value;

    //GUARDAMOS LA ORDEN EN BD
    orderProcess = await productsService.checkout();
    //console.log(orderProcess);
    switch (paymentMethod) {

        case 'clip_payment':
            let params = {
                amount: parseFloat(cart.totalCart.total.replace(/[$,]/g, '')),
                description: 'Tu orden de compra: ' + cart.totalCart.invoiceCode,
                reference: cart.totalCart.invoiceCode,
                name: cart.client_guest.names + ' ' + cart.client_guest.lastnames,
                mail: cart.client_guest.email,
                phone: cart.client_guest.whatsapp,
            }

            resp = await paymentsService.clipPayment(params);
            //console.log(resp.message);
            if (resp.url && resp.url.original.url.status === 'CHECKOUT_CREATED') {
                //orderProcess = await productsService.checkout();
                resp = resp.url.original.url.payment_request_url;
                getAlert(orderProcess.process);
                window.location.href = resp;
            } else {
                //console.log('no paso el pago');
                getAlert('payment_limit_reached');
            }
            break;

        case 'openpay_payment':

            sweetAlert.fire({
                title: '¡No lo olvides!',
                text: 'Al realizar tu pago, por favor da click en "Continuar" o "Seguir comprando" para que el Estatus de tu pago sea actualizado.',
                icon: 'warning',
                confirmButtonText: `<i class="fa fa-thumbs-up"></i> Lo entendí`,
            }).then(function () {
                window.location.href = '/openpay-payment';
            })
            break;

        case 'wire_transfer':

            // Aquí va el código para el pago bancario
            resp = '/confirmacion-pedido';
            //getAlert(orderProcess.process);
            sweetAlert.fire({
                title: '¡Enhorabuena!',
                text: 'La orden ha sido guardada correctamente.',
                icon: 'success',
                confirmButtonText: `Ok`,
            }).then(function () {
                window.location.href = resp;
            })
            break;
    }

}

export async function updateInfoClient(form) {
    let result = valFormulariosV3(form);
    if (result === true) {
        let data = new FormData(document.querySelector('.' + form));
        let resp = await accountService.updateInfoAccount(data);
        getAlert(resp.process);
    }
}

export async function taxesClient(form) {
    let result = valFormulariosV3(form);
    if (result === true) {
        let formElement = document.querySelector('.' + form);
        let data = new FormData(formElement);

        // Verificar si el archivo tax_pdf se está adjuntando correctamente
        let taxPdfFile = formElement.querySelector('input[type="file"]').files[0];
        if (taxPdfFile) {
            data.append('tax_pdf', taxPdfFile);
        }

        let clientId = data.get('client_id');
        if (clientId) {
            let resp = await accountService.updateOrCreateTaxesAccount(clientId, data, {isForm: true});
            getAlert(resp.process, true);
        } else {
            console.error('client_id no se encuentra en los datos del formulario');
        }
    }
}

export async function updateOrCreateAddress(form) {

    let result = valFormulariosV3(form);
    if (result === true) {
        let data = new FormData(document.querySelector('.' + form));
        let addressId = data.get('client_id');
        let resp = null;
        //console.log(addressId, data);
        if (statusAddress) {
            resp = await accountService.updateAddress(addressId, data);
        } else {
            resp = await accountService.addAddress(addressId, data);
        }
        getAlert(resp.process);
    }

}

export async function getCityByState(stateId, cityId = null) {
    let cities = await citiesService.getCityByState(stateId);
    let selectCity = $('#ciudades');
    selectCity.innerHTML = '';
    selectCity.html('');
    cities.forEach(city => {
        let option = $('<option></option>');
        option.val(city.id);
        option.text(city.ciudad);
        console.log(option.val(), option.text());
        selectCity.append(option);
    });

    // Si se proporcionó un ID de ciudad, seleccionarlo
    if (cityId) {
        selectCity.value = cityId;
    }
}

export async function editAddress(id) {

    statusAddress = true;

    let addresses = $('#data_addresses').data('address');
    let selectedAddressId = $('#direcciones').val();
    // Buscar la dirección seleccionada
    let selectedAddress = addresses.find(function (address) {
        return address.id === parseInt(selectedAddressId);
    });
    //console.log(selectedAddress);
    await getCityByState(selectedAddress.state, selectedAddress.city);
    // Llenar el formulario con la información de la dirección seleccionada
    document.querySelector('input[name="address_id"]').value = selectedAddress.id;
    document.querySelector('input[name="identifier"]').value = selectedAddress.identifier;
    document.querySelector('input[name="address"]').value = selectedAddress.address;
    document.querySelector('input[name="exterior_number"]').value = selectedAddress.exterior_number;
    document.querySelector('input[name="interior_number"]').value = selectedAddress.interior_number;
    document.querySelector('input[name="neighborhood"]').value = selectedAddress.neighborhood;
    document.querySelector('input[name="zip_code"]').value = selectedAddress.zip_code;
    document.querySelector('select[name="state"]').value = selectedAddress.state;
    document.querySelector('input[name="contact_name"]').value = selectedAddress.contact_name;
    document.querySelector('input[name="contact_phone"]').value = selectedAddress.contact_phone;
    document.querySelector('#type_address').value = selectedAddress.type_address;
}

export async function editRfc(id, client_id) {

    statusRfc = true;

    if (statusRfc) {
        $('#tax_pdf').show();
    } else {
        $('#tax_pdf').hide();
    }
    let rfcs = $('#selectRfc').data('rfc');
    // Obtener el valor del option seleccionado
    let selectedRfcId = $('#rfc_client').val();

    // Buscar el rfc seleccionado
    let selectedRfc = rfcs.find(function (rfc) {
        return rfc.id === parseInt(selectedRfcId);
    });
    //console.log(selectedRfc);
    // Llenar el formulario con la información de la dirección seleccionada
    document.querySelector('input[name="rfc_id"]').value = selectedRfc.id;
    document.querySelector('input[name="rfc"]').value = selectedRfc.rfc;
    document.querySelector('input[name="business_name"]').value = selectedRfc.business_name;
    document.querySelector('input[name="zip_code"]').value = selectedRfc.zip_code;
    document.querySelector('input[name="phone"]').value = selectedRfc.phone;
    //console.log(selectedRfc.tax_pdf)
    $('.tax_pdf_file').text(selectedRfc.tax_pdf).attr('href','/storage/client/'+ client_id+'/'+selectedRfc.tax_pdf);
    // Establece los valores de los selectores
    $('#regimen-fiscal').val(selectedRfc.cfdi_regime_id);
    $('#cfdi_uses_id').val(selectedRfc.cfdi_uses_id);

}

//ACCEDIENDO DE MANERA GLOBAL A LAS FUNCTIONS
window.updateInfoClient = updateInfoClient;
window.taxesClient = taxesClient;
window.getCityByState = getCityByState;
window.editAddress = editAddress;
window.updateOrCreateAddress = updateOrCreateAddress;
window.addToCart = addToCart;
window.removeFromCart = removeFromCart;
window.updateTotal = updateTotal;
window.updateProductQuantityInCart = updateProductQuantityInCart;
window.fillAddressInputs = fillAddressInputs;
window.saveOrder = saveOrder;
window.editRfc = editRfc;
window.deleteCartCookie = deleteCartCookie;
window.confirmPayment = confirmPayment;


// Variable para rastrear si los event listeners ya se han agregado
let eventListenersAdded = false;
//---- CONFIGURACION GRAL DE JQUERY ----//
$(document).ready(function () {

    const product_detail = $('#product-detail');
    const input_quantity = $('#product-quantity');
    const cart_template = $('#cart-template');
    const indicator_cart = $('.indicator-cart');

    //ESTO SE EJECUTA SI ESTAS EN EL DETALLE DEL PRODUCTO ES PARA ACTUALIZAR EL CAMPO DE CANTIDAD
    let productId = product_detail.data('product-id');

    if (product_detail.length) {

        // Verificar si el carrito existe en el localStorage
        let cart = getCartFromCookie();
        if (cart === null) {
            cart = {};
        } else {
            //SI LA PAGINA DEL PRODUCTO VISITADO ESTA EN EL CARRITO
            //SE ACTUALIZA EL CAMPO DE CANTIDAD
            if (cart[productId]) {
                input_quantity.val(cart[productId].quantity);
            }
        }

        //NECESITO SABER SI EL INPUT DE CANTIDAD DE PRODUCTO ESTA EN LA PAGINA CAMBIA
        input_quantity.on('change', function () {
            // 'this' se refiere al input que ha cambiado.
            quantityProd = Number($(this).val());
            //console.log('en el onchange - ',quantityProd);
        });
    }

    //CUANDO YA ESTOY EN EL CARRITO DEBO ACTUALIZAR EL CAMPO DE CANTIDAD
    // Función para actualizar el total
    if (cart_template.length) {
        indicator_cart.hide();
        // Selecciona todos los botones de incrementar y decrementar
        let incrementButtons = document.querySelectorAll('.increment-btn');
        let decrementButtons = document.querySelectorAll('.decrement-btn');
        if (!eventListenersAdded) {
            // Añade un event listener a cada botón de incrementar
            incrementButtons.forEach(function (button) {

                button.addEventListener('click', function () {

                    // Obtiene el ID del producto
                    let productId = button.dataset.productId;

                    // Obtiene el precio del producto de la cookie sin formato
                    let cart = getCartFromCookie();
                    //console.log('cart-', cart.products[productId]);
                    let productPrice = parseFloat(cart.products[productId].unit_price);

                    // Encuentra el input de cantidad correspondiente
                    let quantityInput = document.querySelector('.quantity-input[data-product-id="' + productId + '"]');

                    // Verifica si quantityInput no es null
                    if (quantityInput) {

                        // Incrementa la cantidad
                        quantityInput.value = parseInt(quantityInput.value);

                        // Calcula el nuevo valor total del producto en el carrito
                        let newTotal = parseInt(quantityInput.value) * productPrice;

                        console.log('quantityInput.value', quantityInput.value, 'productPrice', productPrice, 'newTotal', newTotal);

                        // Encuentra el elemento price_quantity correspondiente
                        let priceQuantityElement = document.querySelector('.price_quantity[data-product-id="' + productId + '"]');
                        priceQuantityElement.textContent = '$' + newTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                        // Actualiza la cantidad del producto en el carrito
                        updateProductQuantityInCart(productId, quantityInput.value);

                        // Actualiza el total
                        updateTotal();
                    } else {
                        console.error('No se encontró el input de cantidad para el producto con id ' + productId);
                    }
                });
            });
            // Añade un event listener a cada botón de decrementar
            decrementButtons.forEach(function (button) {
                button.addEventListener('click', function () {

                    // Obtiene el ID del producto
                    let productId = button.dataset.productId;

                    // Obtiene el precio del producto de la cookie sin formato
                    let cart = getCartFromCookie();
                    let productPrice = parseFloat(cart.products[productId].unit_price);

                    // Encuentra el input de cantidad correspondiente
                    let quantityInput = document.querySelector('.quantity-input[data-product-id="' + productId + '"]');

                    // Verifica si quantityInput no es null
                    if (quantityInput) {

                        // Decrementa la cantidad si es mayor que 1
                        if (parseInt(quantityInput.value) >= 1) {
                            quantityInput.value = parseInt(quantityInput.value);

                            // Actualiza la cantidad del producto en el carrito
                            updateProductQuantityInCart(productId, quantityInput.value);

                            // Calcula el nuevo valor total del producto en el carrito
                            let newTotal = parseInt(quantityInput.value) * productPrice;

                            // Encuentra el elemento price_quantity correspondiente
                            let priceQuantityElement = document.querySelector('.price_quantity[data-product-id="' + productId + '"]');
                            priceQuantityElement.textContent = '$' + newTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Actualiza el total
                            updateTotal();
                        }

                    } else {
                        console.error('No se encontró el input de cantidad para el producto con id ' + productId);
                    }
                });
            });
            eventListenersAdded = true;
        }
    }

    //CHECKOUT
    const is_authenticated = $('#is_authenticated');
    if (is_authenticated.length) {
        document.getElementById('addresses').addEventListener('change', fillAddressInputs);
        fillAddressInputs();
    } else {
        is_guest = true;
    }

});
